import React, { ReactElement } from 'react'
import { Box, Text, Flex, ColorToken } from '@mindfulchefuk/design-system'

import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { CMSImage } from '@mindfulchefuk/features/CMS/components/CMSImage'
import { CMSChecklistImage } from '@mindfulchefuk/features/CMS/interfaces'
import {
  WaveIllustrationWrapper,
  WaveIllustrationWrapperProps,
} from '@mindfulchefuk/design-system/layout/WaveIllustrationWrapper'
import { IconRoundel } from '@mindfulchefuk/features/Homepage/components/IconRoundel/IconRoundel'

type ChecklistImageProps = {
  data: CMSChecklistImage
  titleColor?: ColorToken
  bodyColor?: ColorToken
  checkColor?: ColorToken

  waveIllustrationWrapper?: WaveIllustrationWrapperProps
}

export const ChecklistImage = ({
  data,
  titleColor = 'white',
  bodyColor = 'white',
  checkColor = 'broccoli',
  waveIllustrationWrapper = {},
}: ChecklistImageProps): ReactElement => {
  const imageOrder = data.imageFirst ? 0 : 1

  return (
    <WaveIllustrationWrapper
      {...{ waveTop: 1, waveBottom: 1, ...waveIllustrationWrapper }}
    >
      <Flex
        alignItems="center"
        color={bodyColor}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        maxWidth={{ base: '500px', md: '1000px' }}
        mx="auto"
        px={24}
        py={{ base: 24, sm: 32 }}
        textAlign="center"
      >
        <Flex
          flex="1"
          order={{ md: imageOrder }}
          pl={{ md: imageOrder === 1 ? 32 : 0 }}
          pr={{ md: imageOrder === 0 ? 32 : 0 }}
          mb={{ base: 24 }}
        >
          <Box maxWidth={{ base: '260px', sm: 'none' }} mx="auto">
            <CMSImage src={data.image.path} width={450} />
          </Box>
        </Flex>
        <Box flex="1" mt={{ base: '24px', sm: 0 }}>
          <Text variant="heading-sm" as="h1" color={titleColor} mb={24}>
            {data.title}
          </Text>
          <Box maxWidth={{ sm: '90%' }} mx="auto">
            {data.checklist.map((step) => (
              <Flex alignItems="center" my={16}>
                <Tick fill={checkColor} />
                <Text
                  as="span"
                  textAlign="left"
                  variant={{
                    base: 'body-md',
                    sm: 'body-lg',
                  }}
                >
                  {step.text}
                </Text>
              </Flex>
            ))}

            <Box width="270px" mt={24} mx="auto">
              <CTA
                showCopy={false}
                analytics={{
                  category: ANALYTICS_CATEGORIES.homepage,
                  action: 'stats CTA clicked',
                  label: 'Choose recipe button clicked',
                }}
                text={data.ctaText}
                isFullWidth
              />
            </Box>
          </Box>
        </Box>
      </Flex>
    </WaveIllustrationWrapper>
  )
}
const Tick = ({ fill }: { fill: ColorToken }): ReactElement => (
  <Box pr="12">
    <IconRoundel type="tick" background={fill} mx="auto" size={[36, 32]} />
  </Box>
)
